import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import axios from 'axios'
import App from './App.vue'


/**
 * js
 */
import router from './router/route.js'
import api from './request/api.js'
import config from './configs/appConfig'

/**
 * css
 */
import 'element-plus/dist/index.css'
import './assets/css/iconfont.css'

const app = createApp(App)

app.config.globalProperties.$api = api
app.config.globalProperties.$config = config
app.config.globalProperties.$router = router

app.use(router)
app.use(ElementPlus)

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

app.mount('#app')
