import axios from "axios"
import Tools from '@/tools/tools'
import token from '../tools/tokenTools'
import config from '@/configs/appConfig'
import {ElMessage} from "element-plus";
/**
 * request配置
 */
let axiosConfig = {
    baseURL: config.http
};

/**
 * request请求
 */
const http = axios.create(axiosConfig)

/**
 * 请求拦截器
 */
http.interceptors.request.use((config) => {
    const accessToken = token.get()
    if (accessToken) {
        config.headers.Authorization = Tools.ucFirst(accessToken.token_type) + accessToken.access_token
    }
    token.updateExpiresIn()
    return config
}, function (error) {
    return error
})

/**
 * 响应拦截器
 */
http.interceptors.response.use((response) => {
    return response
}, function (error) {
    // console.log('freddy x', error)
    if (error?.response?.status == 422) {
        ElMessage({
            message: '请求参数有误，请检查：' + JSON.stringify(error?.response?.data?.error),
            type: 'warning',
        })
    }
    if (error?.response?.status == 500) {
        ElMessage({
            message: '服务端异常：' + JSON.stringify(error?.response?.data?.error),
            type: 'error',
        })
    }
    if (error?.response?.status == 400) {
        ElMessage({
            message: error?.response?.data?.error?.message || '400状态码',
            type: 'warning',
        })
    }
    return Promise.reject(error)
})

const request = {
    download: function (url, params, filename) {
        http.get(url, {
            params: params,
            responseType: 'blob'
        }).then(r => {
            const url = window.URL.createObjectURL(new Blob([r.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }))
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.setAttribute('download', filename)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        })
    },
    get: function (url, params, header = {}) {
        return http.get(url, {
            params: params
        }, header)
    },
    getAsync: async function (url, params, header = {}) {
        return await http.get(url, {
            params: params
        }, header).then((r) => {
            return r.data
        }).catch((r) => {
            return r.response.data
        })
    },
    post: function (url, params, header = {}) {
        return http.post(url, params, header)
    },
    put: function (url, params, header = {}) {
        return http.put(url, params, header)
    },
    del: function (url, params, header = {}) {
        return http.delete(url, params, header)
    },
    postAsync: async function (url, params, header = {}) {
        return await http.post(url, params, header).then((r) => {
            return r.data
        }).catch((r) => {
            return r.response.data
        })
    },
    putAsync: async function (url, params, header = {}) {
        return await http.put(url, params, header).then((r) => {
            return r.data
        }).catch((r) => {
            return r.response.data
        })
    },
    deleteAsync: async function (url, params, header = {}) {
        return await http.delete(url, params, header).then((r) => {
            return r.data
        }).catch((r) => {
            return r.response.data
        })
    }
}

export default request
