import {reactive} from 'vue'

export const store = reactive({
    // menu索引相关，默认展开sub-menu的索引
    menuIndex: 1,
    // 面包屑文本
    breadcrumbNames: [
        '首页', '经营数据'
    ],
    // tabs显示列表
    tabsList: [],
    isActive: '',
    /**
     * 获取导航显示序号（index）
     * @param {integer} index 
     */
    setMenuIndex(index) { // 设置展开sub-menu的索引
        this.menuIndex = index
    },
    /**
     * 设置面包屑文本
     * @param {array} breadcrumbNames 
     */
    setBreadcrumbNames(breadcrumbNames) {
        this.breadcrumbNames = breadcrumbNames
    },
    /**
     * 获取面包屑文本
     * @returns array
     */
    getBreadcrumbNames() {
        return this.breadcrumbNames
    },
    /**
     * 设置当前tab标签展示项
     * @param {string} path 
     */
    setIsActive(path) {
        this.isActive = path
    },
    /**
     * 获取当前tab标签默认展示项
     * @returns string
     */
    getIsActive() {
        return this.isActive
    }
})
