/**
 * 路由配置页面，所有的路由统一在这里配置，统一管理
 *
 * 格式：
 * 一级菜单没有component组件，如果要默认显示组件，在children种配置默认组件
 * 配置方式如下：
 * 无children路由配置：
 * （单页面配置，如果登录页面，404页面）
 * {
        name: 'login',                                          # 第一级导航名称
        path: '/login',                                         # 第一级导航路径,必须有左斜线 “/”
        meta: {                                                 # 路由元数据
            icon: ''                                            # 一级导航icon图标，单页面可以没有icon图标
        },
        component: () => import ('@/pages/agents/login.vue')    # 单页面组件导入
    }
 * 有children路由配置：
    {
        name: '商户管理',                                       # 第一级导航名称
        path: '/merchant',                                      # 第一级导航路径,必须有左斜线 “/”
        meta: {                                                 # 路由元数据
            icon: 'icon-shanghuguanli',                         # 一级导航icon图标
            permission: []                                      # 一级导航权限列表
        },
        component: () => import ('@/pages/layout/layout.vue'),  # layout布局组件导入
        children: [                                             # 定义children列表
            {
                name: '新增商户',                                # 二级导航名称
                path: 'new',                                     # 二级导航路径，没有斜线
                meta: {                                          # 二级导航元数据
                    transition: 'slide-right'                    # 二级导航载入动画
                    permission: []                               # 二级导航权限列表
                },
                components: {
                    merchant_new: () => import ('@/pages/merchant/new.vue') #children子组件导入
                                                                            #这里有个很重要的注意点：每个子组件的components定义的时候都要注明router-view的视图命名
                                                                                                    视图命名规则：父组件的路径（path）去掉斜线+‘_’+子组件路径（path）
                }
            }
        ]
    }
 */

const routes = [
    {
        name: '404',
        path: '/404',
        meta: {
            icon: ''
        },
        component: () => import('@/pages/error/404.vue')
    },
    {
        name: 'resetPassword',
        path: '/resetPassword',
        meta: {
            icon: ''
        },
        component: () => import('@/pages/agents/resetPassword.vue')
    },
    {
        name: 'login',
        path: '/login',
        meta: {
            icon: ''
        },
        component: () => import('@/pages/agents/login.vue')
    },
    {
        name: '首页',
        path: '/',
        meta: {
            icon: 'icon-shouye',
            auth: 'auth',
            permission: []
        },
        redirect: '/dashboard',
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '经营数据',
                path: 'dashboard',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    dashboard: () => import('@/pages/home/dashboard.vue')
                }
            }
        ]
    },
    {
        name: '商户管理',
        path: '/merchant',
        meta: {
            icon: 'icon-shanghuguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '新增商户',
                path: 'new',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    merchant_new: () => import('@/pages/merchant/new.vue')
                }
            }, {
                name: '商户列表',
                path: 'list',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    merchant_list: () => import('@/pages/merchant/list.vue')
                }
            }
        ]
    }, {
        name: '业务员管理',
        path: '/salesman',
        meta: {
            icon: 'icon-yewuyuan',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '业务员分润',
                path: 'profit',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    salesman_profit: () => import('@/pages/salesman/profit.vue')
                }
            }, {
                name: '业务员列表',
                path: 'list',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    salesman_list: () => import('@/pages/salesman/list.vue')
                }
            }
        ]
    }, {
        name: '代理商管理',
        path: '/agent',
        meta: {
            icon: 'icon-dailishangguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '代理商列表',
                path: 'list',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    agent_list: () => import('@/pages/agents/list.vue')
                }
            },
            {
                name: '佣金管理',
                path: 'commission',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    agent_commission: () => import('@/pages/agents/commission.vue')
                }
            },
            {
                name: "流量计费",
                path: "walletlogs",
                isshow: true,
                meta: {
                    transition: "slide-right"
                },
                components: {
                    agent_walletlogs: () => import("@/pages/agents/walletlogs.vue")
                },
            },
        ]
    },
    {
        name: '订单管理',
        path: '/order',
        meta: {
            icon: 'icon-dingdanguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '交易订单',
                path: 'list',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    order_list: () => import('@/pages/order/list.vue')
                }
            }, {
                name: '退款订单',
                path: 'refund',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    order_refund: () => import('@/pages/order/refund.vue')
                }
            },
            {
                // name: '代理商统计',
                name:'交易统计',
                path: 'agentStatistics',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    order_agentStatistics: () => import('@/pages/order/agentStatistics.vue')
                }
            },
            // {
            //     name: '交易统计',
            //     path: 'merchantStatistics',
            //     meta: {
            //         transition: 'slide-right'
            //     },
            //     components: {
            //         order_merchantStatistics: () => import('@/pages/order/merchantStatistics.vue')
            //     }
            // }
        ]
    }, {
        name: '发票管理',
        path: '/invoice',
        meta: {
            icon: 'icon-fapiaoguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '申请发票',
                path: 'apply',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    invoice_apply: () => import('@/pages/invoice/apply.vue')
                }
            }, {
                name: '开票记录',
                path: 'record',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    invoice_record: () => import('@/pages/invoice/record.vue')
                }
            }
        ]
    }, {
        name: '结算管理',
        path: '/settlement',
        meta: {
            icon: 'icon-jiesuanguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '结算记录',
                path: 'record',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    settlement_record: () => import('@/pages/settlement/record.vue')
                }
            }, {
                name: '分润结算',
                path: 'profit',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    settlement_profit: () => import('@/pages/settlement/profit.vue')
                }
            }
        ]
    },
    // {
    //     name: '广告管理',
    //     path: '/ad',
    //     meta: {
    //         icon: 'icon-fengkongguanli',
    //         auth: 'auth',
    //         permission: []
    //     },
    //     component: () => import('@/pages/layout/layout.vue'),
    //     children: [
    //         {
    //             name: '广告列表',
    //             path: 'list',
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 ad_list: () => import('@/pages/ad/list.vue')
    //             }
    //         }
    //     ]
    // },
    {
        name: '终端管理',
        path: '/device',
        meta: {
            icon: 'icon-shebeiguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '设备列表',
                path: 'record',
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    device_record: () => import('@/pages/device/list.vue')
                }
            },
            {
                name: "终端列表",
                path: "terminal",
                isshow: true,
                meta: {
                    transition: "slide-right"
                },
                components: {
                    device_terminal: () => import("@/pages/device/terminal.vue")
                }
            }
        ]
    }
]

export default routes;
