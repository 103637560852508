import config from '@/configs/appConfig'

const pathTools = {
    /**
    * 加载path文件种定义的组件
    * @param {*} router 
    * @returns 
    */
    importComponents: function (router) {
        let newParent = []
        const defaultComponentPath = 'pages'

        router.forEach(parent => {
            let newChildren = [];
            const parentPath = parent.path

            if (parent.children) {
                parent.children.forEach(children => {
                    let childrenComponent = children.component
                    const pPath = parentPath == '/' ? '/home' : parentPath
                    children.component = () => import ('@/' + defaultComponentPath + pPath + '/' + childrenComponent + '.vue')
                    newChildren.push(children)
                })
            }

            parent.children = newChildren
            newParent.push(parent)

        });

        return newParent
    },
    /**
     * 组装path文件中的各个组件的路径，把路径从path里面的json格式保存到本地
     * @param {*} router 
     * @returns 
     */
    putPath: function (router = '') 
    {   
        //登录获取前清除
        localStorage.clear();
        const storagePath = localStorage.getItem('storagePathAgent')
        if (storagePath) {
            return JSON.parse(storagePath)
        }

        let path = []
        let menuItemJson = {}
        router.forEach(parent => {
            const parenPathInfo = {
                name: parent.name,
                path: parent.path,
                meta: parent.meta
            }

            let childrenPath = []
            let cMenuItemJson = {}
            if (parent.children) {
                parent.children.forEach((children) => {
                    let childrenPathInfo = {}
                    if (parent.path == '/') {
                        childrenPathInfo = {
                            name: children.name,
                            path: '/' + children.path,
                            meta: children.meta
                        }
                    } else {
                        childrenPathInfo = {
                            name: children.name,
                            path: parent.path + '/' + children.path,
                            meta: children.meta
                        }
                    } childrenPath.push(childrenPathInfo)

                    cMenuItemJson[children.path] = children.name
                })

                parenPathInfo.children = childrenPath
            }

            path.push(parenPathInfo)

            menuItemJson[parent.path.split('/')[1]] = {
                name: parent.name,
                children: cMenuItemJson
            }

        })

        if (config.storagePath) {
            localStorage.setItem('menuItemsAgent', JSON.stringify(menuItemJson))
            localStorage.setItem('storagePathAgent', JSON.stringify(path))
        }

        return path
    },
    /**
     * 加载路径
     * @returns 
     */
    loadPath: function () {
        const storagePath = localStorage.getItem('storagePathAgent')
        if (storagePath) {
            return JSON.parse(storagePath)
        }
        return []
    },
    /**
     * 加载按钮格式化名称
     * @returns 
     */
    loadMenuItem: function () {
        const menuItems = localStorage.getItem('menuItemsAgent')
        if (menuItems) {
            return JSON.parse(menuItems)
        }
        return {}
    },
    /**
     * 路径数组扁平化
     * @param {*} routes 
     * @returns 
     */
    pathDelayer: function (routes) {
        const pathStorage = localStorage.getItem('pathDelayerAgent')
        if (pathStorage) {
            return JSON.parse(pathStorage);
        }

        let path = [];
        routes.forEach(router => {
            path.push(router.path)
            if (router.children) {
                router.children.forEach(route => {
                    if (route.path == '') {
                        return
                    }
                    path.push(route.path)
                })
            }
        })

        localStorage.setItem('pathDelayerAgent', JSON.stringify(path))

        return path
    }
};

export default pathTools;
